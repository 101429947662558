import without from 'lodash/without'
import { toRaw } from 'vue'

import countriesJSON from '@/static/countries.json'
import dataCenters from '@/static/dataCenters'
import iabDeviceTypes from '@/static/iab-device-types'
import mediatypes from '@/static/mediatypes'
import sizes from '@/static/sizes'

import { BLACKLIST_ACTION } from '@/types/common'
import { FormType } from '@/types/form'

import { ContextType } from '@/plugins/context/context.enum'
import i18n from '@/plugins/i18n'

import { useContextStore } from '@/store/context.store'

import { VariableType } from '@/models/enum/variableType'
import { SelectResponse } from '@/models/select'

import { fetchPublishersForSelect } from '@/services/publishers'
import { fetchSeatsForSelect } from '@/services/seats'
import { fetchVariablesForSelect } from '@/services/variables'
import { fetchWebsitesForSelect } from '@/services/websites'

export type TargetingProperty =
| 'countries'
| 'cities'
| 'devices'
| 'sizes'
| 'publishers'
| 'websites'
| 'environments'
| 'placements'
| 'pagetypes'
| 'categories'
| 'subcategories'
| 'mediatypes'
| 'keywords'
| 'nogdpr'
| 'requiredbuyeruid'
| 'segments'
| 'adunitcodes'
| 'divids'
| 'gpids'
| 'domains'
| 'datacenters'
| 'seats'

export interface SearchTargetingValueAsyncFn {
  (q?: string) : Promise<Array<{id: string | number, name: string }>>
}

export interface ResolveSelectedTargetingValuesAsyncFn {
  (ids: string[]) : Promise<Array<{id: string | number, name: string }>>
}

export interface TargetingItem {
  action: string,
  values: string[]
  metas: {
    isNew?: boolean
    isOpen?: boolean
  }
}

export interface TargetingSelectItem {
  id: string
  name: string
}

export interface BulkTargetingItem {
  action: 'wl' | 'bl'
  values: TargetingSelectItem[]
}

export interface Targeting {
  countries?: TargetingItem
  cities?: TargetingItem
  devices?: TargetingItem
  sizes?: TargetingItem
  publishers?: TargetingItem
  websites?: TargetingItem
  environments?: TargetingItem
  placements?: TargetingItem
  pagetypes?: TargetingItem
  categories?: TargetingItem
  subcategories?: TargetingItem
  mediatypes?: TargetingItem
  keywords?: TargetingItem
  nogdpr?: TargetingItem
  requiredbuyeruid?: TargetingItem
  segments?: TargetingItem
  adunitcodes?: TargetingItem
  divids?: TargetingItem
  gpids?: TargetingItem
  domains?: TargetingItem
  datacenters?: TargetingItem
  seats?: TargetingItem
}

export interface TargetingField {
  name: keyof Targeting
  label: string,
  valueProp?: 'id' | 'name' // The valueProp attribute for the TargetingItem multiselect
  trackBy?: string // The name(s) of the properties that should be searched when searchable is true
  multiselectMode: 'tags' | 'single'
  searchable?: boolean
  tooltip?: string
  mandatory?: boolean // If true, it will be auto-selected and cannot be unselected. It still needs to be added into the form in the form constructor.
  createOption?: boolean
  async?: boolean
  fetch?: SearchTargetingValueAsyncFn
  resolve?: ResolveSelectedTargetingValuesAsyncFn
}

export interface FloorRuleTargeting extends Pick<Targeting, 'countries' | 'sizes' | 'categories' | 'mediatypes' | 'websites' | 'environments'> {}

export interface RefreshRuleTargeting extends Pick<Targeting, 'websites' | 'devices' | 'pagetypes' | 'placements' | 'countries'> {}

export interface ShapingRuleTargeting extends Pick<Targeting, 'websites' | 'publishers' | 'sizes' | 'devices' | 'countries'> {}

// Deprecated targetings can only be selected and edited if the resource being edited already has this targeting and cannot be used in creations
export const DEPRECATED_TARGETINGS: TargetingProperty[] = []

// filterDeprecatedTargetings removes the deprecated targetings from the given targetings.
// If the given action is EDIT or undefined, only the deprecated targetings that are NOT present in the given currentTargetings
// are removed.
export function filterDeprecatedTargetings (targetings: TargetingProperty[], action: FormType | undefined, currentTargetings: object): TargetingProperty[] {
  if (action === FormType.CREATE) {
    return without(targetings, ...DEPRECATED_TARGETINGS)
  }
  const keys = Object.keys(toRaw(currentTargetings))
  return targetings.filter(t => keys.includes(t) || !DEPRECATED_TARGETINGS.includes(t))
}

export function createTargetingItemForm (data?: Partial<TargetingItem>): TargetingItem {
  return {
    action: data?.action || BLACKLIST_ACTION,
    values: data?.values || [],
    metas: data?.metas || { isNew: true }
  }
}

export function createBulkTargetingItem (): BulkTargetingItem {
  return { action: 'wl', values: [] }
}

function ensureIdIsString (data: SelectResponse[]): SelectResponse[] {
  data.forEach(item => {
    if (typeof item.id === 'number') {
      item.id = item.id.toString()
    }
  })

  return data
}

export const targetings: TargetingField[] = [
  {
    name: 'countries',
    label: i18n.global.t('labels.country', 2),
    multiselectMode: 'tags',
    searchable: true,
    trackBy: 'name',
    fetch () {
      return Promise.resolve(countriesJSON)
    }
  },
  // {
  //   name: ETargetingProperty.cities,
  //   label: 'Cities'
  // },
  {
    name: 'devices',
    label: i18n.global.t('labels.device', 2),
    multiselectMode: 'tags',
    fetch () {
      return Promise.resolve(iabDeviceTypes.sort((a, b) => a.name.localeCompare(b.name)))
    }
  },
  {
    name: 'sizes',
    label: i18n.global.t('labels.size', 2),
    multiselectMode: 'tags',
    searchable: true,
    fetch () {
      return Promise.resolve(sizes.sort((a, b) => a.name.localeCompare(b.name)))
    }
  },
  {
    name: 'nogdpr',
    label: i18n.global.t('labels.noGDPR', 2),
    multiselectMode: 'single',
    searchable: false,
    fetch () {
      return Promise.resolve([{ id: '1', name: 'true' }, { id: '0', name: 'false' }])
    }
  },
  {
    name: 'requiredbuyeruid',
    label: i18n.global.t('labels.requiredBuyerUID', 2),
    multiselectMode: 'single',
    searchable: false,
    fetch () {
      return Promise.resolve([{ id: '1', name: 'true' }, { id: '0', name: 'false' }])
    }
  },
  {
    name: 'adunitcodes',
    label: i18n.global.t('labels.adunitcode', 2),
    multiselectMode: 'tags',
    searchable: true,
    createOption: true
  },
  {
    name: 'divids',
    label: i18n.global.t('labels.divid', 2),
    multiselectMode: 'tags',
    searchable: true,
    createOption: true
  },
  {
    name: 'gpids',
    label: i18n.global.t('labels.gpid', 2),
    multiselectMode: 'tags',
    searchable: true,
    createOption: true
  },
  {
    name: 'domains',
    label: i18n.global.t('labels.domain', 2),
    multiselectMode: 'tags',
    searchable: true,
    createOption: true
  },
  {
    name: 'websites',
    label: i18n.global.t('labels.website', 2),
    multiselectMode: 'tags',
    searchable: true,
    async: true,
    async fetch (q) {
      return await fetchWebsitesForSelect({ search: q, filter: ['deleted_at||$isnull', 'is_active||$istrue'] }).then(response => {
        if (response.status === 200) {
          // sort locally as goyave sort is case sensitive and we want an insensitive one here see https://app.shortcut.com/adagio-dot-io/story/13009/filter-add-sort-operator
          return ensureIdIsString(response.data.sort((a, b) => a.name.localeCompare(b.name)))
        }
        throw new Error('Fetch error')
      })
    },
    async resolve (ids: string[]) {
      if (ids.length === 0) {
        return []
      }
      return await fetchWebsitesForSelect({ filter: `id||$in||${ids.join(',')}` }).then(response => {
        if (response.status === 200) {
          return ensureIdIsString(response.data)
        }
        throw new Error('Fetch error')
      })
    }
  },
  {
    name: 'publishers',
    label: i18n.global.t('labels.publisher', 2),
    multiselectMode: 'tags',
    searchable: true,
    async: true,
    async fetch (q) {
      return await fetchPublishersForSelect({ search: q }).then(response => {
        if (response.status === 200) {
          // sort locally as goyave sort is case sensitive and we want an insensitive one here see https://app.shortcut.com/adagio-dot-io/story/13009/filter-add-sort-operator
          return ensureIdIsString(response.data.sort((a, b) => a.name.localeCompare(b.name)))
        }
        throw new Error('Fetch error')
      })
    },
    async resolve (ids: string[]) {
      if (ids.length === 0) {
        return []
      }
      return await fetchPublishersForSelect({ filter: `id||$in||${ids.join(',')}` }).then(response => {
        if (response.status === 200) {
          return ensureIdIsString(response.data)
        }
        throw new Error('Fetch error')
      })
    }
  },
  {
    name: 'environments',
    valueProp: 'name',
    label: i18n.global.t('labels.environment', 2),
    multiselectMode: 'tags',
    searchable: true,
    async: true,
    async fetch (q) {
      return await fetchVariablesForSelect(VariableType.ENVIRONMENT, { search: q }).then(response => {
        if (response.status === 200) {
          return ensureIdIsString(response.data)
        }
        throw new Error('Fetch error')
      })
    },
    async resolve (ids: string[]) {
      if (ids.length === 0) {
        return []
      }
      return await fetchVariablesForSelect(VariableType.ENVIRONMENT, { filter: `value||$in||${ids.join(',')}` }).then(response => {
        if (response.status === 200) {
          return ensureIdIsString(response.data)
        }
        throw new Error('Fetch error')
      })
    }
  },
  {
    name: 'placements',
    valueProp: 'name',
    label: i18n.global.t('labels.placement', 2),
    multiselectMode: 'tags',
    searchable: true,
    async: true,
    async fetch (q) {
      return await fetchVariablesForSelect(VariableType.PLACEMEMENT, { search: q, sort: 'name,ASC' }).then(response => {
        if (response.status === 200) {
          return ensureIdIsString(response.data)
        }
        throw new Error('Fetch error')
      })
    },
    async resolve (ids: string[]) {
      if (ids.length === 0) {
        return []
      }
      return await fetchVariablesForSelect(VariableType.PLACEMEMENT, { filter: `value||$in||${ids.join(',')}` }).then(response => {
        if (response.status === 200) {
          return ensureIdIsString(response.data)
        }
        throw new Error('Fetch error')
      })
    }
  },
  {
    name: 'pagetypes',
    valueProp: 'name',
    label: i18n.global.t('labels.pagetype', 2),
    multiselectMode: 'tags',
    searchable: true,
    async: true,
    async fetch (q) {
      return await fetchVariablesForSelect(VariableType.PAGETYPE, { search: q }).then(response => {
        if (response.status === 200) {
          return ensureIdIsString(response.data)
        }
        throw new Error('Fetch error')
      })
    },
    async resolve (ids: string[]) {
      if (ids.length === 0) {
        return []
      }
      return await fetchVariablesForSelect(VariableType.PAGETYPE, { filter: `value||$in||${ids.join(',')}` }).then(response => {
        if (response.status === 200) {
          return ensureIdIsString(response.data)
        }
        throw new Error('Fetch error')
      })
    }
  },
  {
    name: 'categories',
    valueProp: 'name',
    label: i18n.global.t('labels.category', 2),
    multiselectMode: 'tags',
    searchable: true,
    async: true,
    async fetch (q) {
      return await fetchVariablesForSelect(VariableType.CATEGORY, { search: q }).then(response => {
        if (response.status === 200) {
          return ensureIdIsString(response.data)
        }
        throw new Error('Fetch error')
      })
    },
    async resolve (ids: string[]) {
      if (!ids.length) {
        return []
      }
      return await fetchVariablesForSelect(VariableType.CATEGORY, { filter: `value||$in||${ids.join(',')}` }).then(response => {
        if (response.status === 200) {
          return ensureIdIsString(response.data)
        }
        throw new Error('Fetch error')
      })
    }
  },
  {
    name: 'subcategories',
    valueProp: 'name',
    label: i18n.global.t('labels.subcategory', 2),
    multiselectMode: 'tags',
    searchable: true,
    async: true,
    async fetch (q) {
      return await fetchVariablesForSelect(VariableType.SUBCATEGORY, { search: q }).then(response => {
        if (response.status === 200) {
          return ensureIdIsString(response.data)
        }
        throw new Error('Fetch error')
      })
    },
    async resolve (ids: string[]) {
      if (ids.length === 0) {
        return []
      }
      return await fetchVariablesForSelect(VariableType.SUBCATEGORY, { filter: `value||$in||${ids.join(',')}` }).then(response => {
        if (response.status === 200) {
          return ensureIdIsString(response.data)
        }
        throw new Error('Fetch error')
      })
    }
  },
  {
    name: 'mediatypes',
    label: i18n.global.t('labels.mediatype', 2),
    multiselectMode: 'tags',
    searchable: true,
    async fetch () {
      return Promise.resolve(mediatypes)
    }
  },
  {
    name: 'keywords',
    label: i18n.global.t('labels.keyword', 2),
    multiselectMode: 'tags',
    searchable: true,
    createOption: true
  },
  {
    name: 'segments',
    label: i18n.global.t('labels.segment', 2),
    multiselectMode: 'tags',
    searchable: true,
    tooltip: i18n.global.t('targetings.segmentsTooltip'),
    fetch () {
      const segments: Array<{id: string | number, name: string }> = []
      for (let i = 0; i < 100; i++) {
        const id = i < 10 ? `0${i}` : `${i}`
        segments.push({
          id,
          name: `Segment ${id}`
        })
      }
      return Promise.resolve(segments)
    }
  },
  {
    name: 'datacenters',
    label: i18n.global.t('labels.dataCenter', 2),
    multiselectMode: 'tags',
    searchable: true,
    fetch () {
      return Promise.resolve(dataCenters)
    }
  },
  {
    name: 'seats',
    label: i18n.global.t('labels.seat', 2),
    multiselectMode: 'tags',
    searchable: true,
    async: true,
    async fetch (q) {
      const filters = []

      const contextStore = useContextStore()

      if (contextStore.hasContext) {
        if (contextStore.contextType === ContextType.PUBLISHERS) {
          filters.push(`publisher_id||$in||${contextStore.contextId}`)
        }
      }

      return await fetchSeatsForSelect(undefined, q, undefined, filters).then(response => {
        if (response.status === 200) {
          return ensureIdIsString(response.data)
        }
        throw new Error('Fetch error')
      })
    },
    async resolve (ids: string[]) {
      if (ids.length === 0) {
        return []
      }
      return await fetchSeatsForSelect(undefined, undefined, ids).then(response => {
        if (response.status === 200) {
          return ensureIdIsString(response.data)
        }
        throw new Error('Fetch error')
      })
    }
  }
]

export const targetingsByName = targetings.reduce((acc, t) => {
  acc[t.name] = t
  return acc
}, {} as Record<string, TargetingField>)
