<template>
  <div class="divide-y divide-gray-border">
    <FormFields
      v-model="reportForm"
      :fields="commonFields"
      :errors="errors"
      :can-edit="!viewOnly"
    />
  </div>
</template>

<script lang="ts">

import { ErrorObject } from '@vuelidate/core'
import { PropType, computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRequest } from 'vue-request'

import { PUBLISHER_DIRECT_SEAT_REPORTS_SCHEMA, PUBLISHER_REPORTS_SCHEMA_V1, cubeSchemas, dayOfExportMonthOptions, dayOfExportWeekOptions, periodOptions } from '@/static/reports'
import timezones from '@/static/timezones'

import { FormSelectOption, FormType } from '@/types/form'

import { DataDefinition } from '@/plugins/dashboard/definition'
import { CUBE_ENDPOINT, CubeSource } from '@/plugins/dashboard/source'
import { FieldTemplate } from '@/plugins/form'
import { FormInput, FormMultiselect, FormSection, FormTextarea, FormToggle } from '@/plugins/form/renderer'

import { useContextStore } from '@/store/context.store'
import { useNotificationsStore } from '@/store/notifications.store'

import { ReportForm } from '@/models/report'

import { fetchSeatsForPublisher } from '@/services/seats'

import api from '@/api'
import FormFields from '@/components/FormBuilder/FormFields.vue'

export default defineComponent({
  components: { FormFields },
  props: {
    modelValue: {
      type: Object as PropType<ReportForm>,
      required: true
    },
    errors: {
      type: Array as PropType<ErrorObject[]>,
      required: false,
      default: () => []
    },
    viewOnly: {
      type: Boolean,
      required: false,
      default: () => false
    },
    action: {
      type: String as PropType<FormType | undefined>,
      required: false,
      default: undefined
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const { t } = useI18n()

    const contextStore = useContextStore()
    const notificationsStore = useNotificationsStore()

    const reportForm = computed<ReportForm>({
      get () {
        return props.modelValue
      },
      set (value: any) {
        emit('update:modelValue', value)
      }
    })

    const tables = ref({} as Record<string, DataDefinition>)
    const sources = ref<Array<{id: any, name: string}>>([])
    const dimensions = ref<Array<{id: any, name: string}>>([])
    const metrics = ref<Array<{id: any, name: string}>>([])

    const currentPublisher = computed(() => contextStore.contextSelectedPublisher)

    const fetchCubeSources = () => {
      const source = new CubeSource('SourceForm', '', api.contextURL(CUBE_ENDPOINT, `${import.meta.env.VITE_DATA_API_BASE_URL}/api/v1`), '')

      const schemas = cubeSchemas()

      source.getExistingTables()
        .then(t => {
          tables.value = t
          sources.value = Object.keys(t).filter(tName => {
            return Object.prototype.hasOwnProperty.call(schemas[contextStore.contextType], tName)
          }).map(tName => {
            return { id: tName, name: schemas[contextStore.contextType][tName]?.title }
          })
          computeSources()
          updateDayOfExportMonthOptions()
        })
        .catch((e: any) => {
          notificationsStore.add({
            message: t('messages.fetchErrorWithMsg', [e.message]),
            type: 'error'
          })
        })
    }

    // Lifecycle
    onMounted(async () => {
      if (currentPublisher.value) {
        useRequest(fetchSeatsForPublisher, {
          manual: false,
          defaultParams: [currentPublisher.value?.id || 0],
          onSuccess: (response) => {
            if (response?.data) {
              fetchCubeSources()
            }
          }
        })
      } else {
        fetchCubeSources()
      }
    })

    const computeSources = () => {
      if (reportForm.value.source) {
        const currentSource = sources.value.find(s => s.id === reportForm.value.source)
        if (currentSource) {
          updateDimMetrics(currentSource.id)
        }
      }
    }

    watch(
      () => props.modelValue.source,
      () => {
        nextTick(() => {
          computeSources()
          updateDayOfExportMonthOptions()
        })
      }
    )

    const updateDimMetrics = (sourceId: string) => {
      const schemas = cubeSchemas()

      dimensions.value = tables.value[sourceId].dimensions.filter(v => {
        return schemas[contextStore.contextType][sourceId] && !schemas[contextStore.contextType][sourceId].blacklisted.dimensions.includes(v.name)
      }).map(v => {
        const option = { id: v.name, name: v.name }

        if ([PUBLISHER_DIRECT_SEAT_REPORTS_SCHEMA].includes(sourceId) && v.name === 'windowStart') {
          // Map `windowStart` field to `day` dimension to keep all exports consistent.
          // In Cube schema, we kept `wiwndowStart` to avoid to many custom change when we request a report from the api.
          // The code below is for a display purpose only.
          option.name = 'day'
        }

        if (sourceId === PUBLISHER_REPORTS_SCHEMA_V1) {
          const deprecated = schemas[contextStore.contextType][sourceId].deprecated.dimensions

          if (deprecated.includes(v.name)) {
            option.name = `${option.name} (deprecated)`
          }
        }

        return option
      })

      metrics.value = Object.keys(tables.value[sourceId].metrics).filter(v => {
        return schemas[contextStore.contextType][sourceId] && !schemas[contextStore.contextType][sourceId].blacklisted.metrics.includes(v)
      }).map(v => { return { id: v, name: v } })
    }

    const dayOfExportOptions = ref<FormSelectOption[]>([])

    const updateDayOfExportMonthOptions = () => {
      switch (reportForm.value.period) {
        case 'past_week':
        case 'rolling_week':
          dayOfExportOptions.value = dayOfExportWeekOptions()
          break
        case 'past_month':
        case 'rolling_month':
          dayOfExportOptions.value = dayOfExportMonthOptions()
          break
        default:
          dayOfExportOptions.value = []
          break
      }
    }

    const commonFields = computed<Array<FieldTemplate<unknown>>>(() => [
      {
        renderer: new FormSection({ title: t('labels.generalSettings'), mode: 'disclosure', isDefaultOpen: true }),
        wrapperClass: 'p-5 space-y-4',
        isSlotComponent: true,
        fields: [
          {
            key: 'isActive',
            columnGrid: 12,
            placement: 'sectionHeader',
            renderer: new FormToggle({ name: 'isActive', label: t('labels.isActive'), labelPosition: 'left' })
          },
          {
            wrapperClass: 'grid grid-rows-1 grid-cols-12 gap-2.5',
            fields: [
              {
                key: 'name',
                columnGrid: 12,
                renderer: new FormInput({
                  name: 'name',
                  isRequired: true,
                  label: t('labels.name')
                })
              },
              {
                key: 'tokenUrl',
                columnGrid: 9,
                renderer: new FormInput({
                  modelValue: reportForm.value.directLink ? `${reportForm.value.tokenUrl}?format=csv` : reportForm.value.tokenUrl,
                  name: 'tokenUrl',
                  label: t('labels.tokenUrl'),
                  attr: { readonly: true }
                })
              },
              {
                key: 'directLink',
                columnGrid: 3,
                renderer: new FormToggle({
                  name: 'directLink',
                  label: t('labels.directLink'),
                  wrapperClass: 'pt-3',
                  attr: { readonly: false }
                })
              },
              {
                key: 'source',
                columnGrid: 12,
                renderer: new FormMultiselect({
                  name: 'source',
                  label: t('labels.source'),
                  isRequired: true,
                  options: sources.value,
                  attr: {
                    mode: 'single',
                    'close-on-select': true,
                    canClear: false,
                    disabled: props.action !== FormType.CREATE
                  },
                  onSelect: computeSources
                })
              },
              {
                key: 'dimensions',
                columnGrid: 12,
                renderer: new FormMultiselect({
                  name: 'dimensions',
                  label: t('labels.dimension', 2),
                  mode: 'tags',
                  isRequired: true,
                  options: dimensions.value,
                  hints: { textBottom: t('reports.dimensionsHelpText') }
                })
              },
              {
                key: 'metrics',
                columnGrid: 12,
                renderer: new FormMultiselect({
                  name: 'metrics',
                  label: t('labels.metric', 2),
                  mode: 'tags',
                  isRequired: true,
                  options: metrics.value
                })
              },
              {
                key: 'timezone',
                columnGrid: 12,
                renderer: new FormMultiselect({ name: 'timezone', label: t('labels.timezone'), isRequired: true, options: timezones, attr: { mode: 'single', searchable: true, closeOnSelect: true } })
              }
            ]
          }
        ]
      },
      {
        renderer: new FormSection({ title: t('labels.scheduling'), mode: 'disclosure', isDefaultOpen: true }),
        wrapperClass: 'p-5 space-y-4',
        isSlotComponent: true,
        fields: [
          {
            key: 'isScheduled',
            renderer: new FormToggle({
              name: 'isScheduled',
              label: t('labels.scheduled'),
              labelPosition: 'right',
              attr: { readonly: true }
            })
          },
          {
            key: 'period',
            renderer: new FormMultiselect({
              name: 'period',
              label: t('labels.period'),
              isRequired: true,
              options: periodOptions(),
              onSelect: updateDayOfExportMonthOptions,
              attr: { mode: 'single', searchable: true, closeOnSelect: true }
            })
          },
          {
            key: 'dayOfExport',
            condition: () => !!(reportForm.value.period && !['yesterday', 'last_7_days'].includes(reportForm.value.period)),
            renderer: new FormMultiselect({
              name: 'dayOfExport',
              label: t('labels.dayOfExport'),
              isRequired: true,
              options: dayOfExportOptions.value,
              attr: { mode: 'single', searchable: true, closeOnSelect: true }
            })
          },
          {
            key: 'emails',
            renderer: new FormTextarea({
              name: 'emails',
              label: t('labels.email', 2),
              isRequired: false,
              attr: { placeholder: 'List of emails separated by a comma ","' }
            })
          }
        ]
      }
    ])

    return {
      t,
      reportForm,
      commonFields,
      dimensions
    }
  }
})

</script>
