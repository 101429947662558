import { computed, ComputedRef } from 'vue'
import { LocationQueryRaw, RouteLocationNormalized, RouteLocationRaw } from 'vue-router'

import { useAuthStore } from '@/store/auth.store'
import { useContextStore } from '@/store/context.store'

import { router } from '@/router'

import { ContextType } from './context.enum'

export const ContextParam: Record<string, ContextType> = {
  publisherId: ContextType.PUBLISHERS,
  bidderId: ContextType.BIDDERS,
  groupId: ContextType.GROUPS
}

export interface Context {
  resourceId: number
  resourceType: ContextType
}

// useRouteContext clear the context if the route doesn't contains context id
export function useRouteContext (to: RouteLocationNormalized): void {
  const contextStore = useContextStore()
  const authStore = useAuthStore()

  if (authStore.isLogged) {
    if (contextStore.hasContext) {
      if (!to.params.publisherId && !to.params.groupId && !to.params.bidderId) {
        contextStore.clearContext()
      }
    }
  }
}

// useContext return a RouteLocationRaw with the context in the route params
export function useContext (routeName: string, routeParams: object, query?: LocationQueryRaw): ComputedRef<RouteLocationRaw> {
  const contextStore = useContextStore()

  if (contextStore.hasContext) {
    if (router) {
      const contextType = computed(() => contextStore.contextType)

      const route = computed(() => `${contextType.value}.${routeName}`)

      const contextRouteParam = computed(() => contextStore.contextRouteParam)

      const routeWithContext = computed<RouteLocationRaw>(() => ({ name: route.value, params: { ...routeParams, ...contextRouteParam.value }, query }))

      return routeWithContext
    }
  }

  return computed<RouteLocationRaw>(() => ({ name: 'home', params: {} }))
}

/**
 * contextFromRoute return a Context or undefined based on the route params of the
 * given route location.
 *
 * For example, if there is a param "publisherId", then a Context of type "publishers"
 * will be returned with the associated ID.
 */
export function contextFromRoute (route: RouteLocationRaw): (Context | undefined) {
  if (typeof route === 'string' || !('params' in route) || route.params === undefined) {
    return undefined
  }
  const param = Object.entries(route.params).find(e => ContextParam[e[0]] !== undefined)
  if (param === undefined) {
    return undefined
  }
  return {
    resourceType: ContextParam[param[0]],
    resourceId: parseInt(param[1] as string)
  }
}
