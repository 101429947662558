<template>
  <div class="min-h-screen flex flex-col px-2 py-8 lg:flex-row lg:p-0">
    <div class="lg:w-1/2 lg:flex lg:flex-col lg:justify-center">
      <router-view />
    </div>
    <div
      class="hidden lg:block lg:flex-1 lg:relative lg:ml-auto"
    >
      <span
        class="block bg-cover bg-no-repeat bg-left-top sticky w-full h-full"
        :style="`background-image: url(${storageUrl}/img/adagio-connexion.png);`"
      />
    </div>
    <div
      class="fixed -z-10 pointer-events-none h-[40%] sm:h-[55%] w-full bottom-0 right-0 lg:hidden"
    >
      <span
        class="block bg-cover bg-no-repeat bg-left-top w-full h-full"
        :style="`background-image: url(${storageUrl}/img/adagio-connexion-mobile.png);`"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    const storageUrl = import.meta.env.VITE_STORAGE_URL

    return {
      storageUrl
    }
  }
})

</script>
